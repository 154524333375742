
export type ThemeData = {
    backdrop?: string,
    actionButtonText: string,
    buttonTextColor: string,
    logo: string,
    placeholderText: string,
    primary: ThemeColor,
    secondary?: ThemeColor,
    headerLogo?: string,
    footerLogo?: string,
    footerLogoHeight?: string,
}

export type ThemeColor = {
    DEFAULT: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    950?: string;
    text: string,
    textMuted?: string,
}

export const THEME_COLORS: { [key: string]: ThemeData } = {
    "imxMarketplace": {
        backdrop: "0, 121, 133",
        actionButtonText: '0, 0, 0',
        buttonTextColor: '255, 255, 255',
        placeholderText: '140, 152, 192',
        logo: '255, 255, 255',
        footerLogo: 'none',
        primary: {
            DEFAULT: '46, 236, 255',
            '50': '230, 253, 255',
            '100': '209, 251, 255',
            '200': '168, 247, 255',
            '300': '128, 243, 255',
            '400': '87, 240, 255',
            '500': '46, 236, 255',
            '600': '0, 232, 255',
            '700': '0, 172, 189',
            '800': '0, 121, 133',
            '900': '0, 70, 77',
            'text': '255, 255, 255',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '17, 29, 54',
            '50': '49, 60, 155',
            '100': '46, 59, 147',
            '200': '35, 42, 112',
            '300': '32, 41, 101',
            '400': '28, 39, 89',
            '500': '22, 37, 70',
            '600': '20, 33, 62',
            '700': '17, 29, 54',
            '800': '15, 25, 47',
            '900': '12, 21, 39',
            '950': '11, 17, 35',
            'text': '209, 251, 255',
        },
    },
    "ea7df14a1597407f9f755f05e25bab42": {
        backdrop: "0, 121, 133",
        placeholderText: '198, 242, 246',
        actionButtonText: '0, 0, 0',
        buttonTextColor: '255, 255, 255',
        logo: '255, 255, 255',
        footerLogo: 'none',
        primary: {
            DEFAULT: '128, 226, 235',
            '50': '255, 255, 255',
            '100': '255, 255, 255',
            '200': '234, 250, 252',
            '300': '198, 242, 246',
            '400': '163, 234, 241',
            '500': '128, 226, 235',
            '600': '80, 215, 227',
            '700': '34, 201, 217',
            '800': '26, 156, 168',
            '900': '19, 111, 120',
            '950': '15, 89, 96',
            'text': '255, 255, 255',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '46, 89, 112',
            '50': '193, 217, 230',
            '100': '179, 208, 224',
            '200': '150, 191, 212',
            '300': '121, 173, 200',
            '400': '92, 155, 188',
            '500': '34, 66, 83',
            '600': '16, 35, 49',
            '700': '15, 29, 39',
            '800': '34, 66, 83',
            '900': '22, 43, 54',
            '950': '14, 27, 34',
            'text': '209, 251, 255',
        }
    },
    "light": {
        placeholderText: '134, 134, 134',
        actionButtonText: '255, 255, 255',
        buttonTextColor: '17, 17, 17',
        logo: '255, 0, 147',
        footerLogo: 'none',
        primary: {
            DEFAULT: '228, 37, 117',
            '50': '248, 200, 220',
            '100': '246, 182, 209',
            '200': '241, 146, 186',
            '300': '237, 110, 163',
            '400': '232, 73, 140',
            '500': '228, 37, 117',
            '600': '166, 51, 94',
            '700': '136, 17, 67',
            '800': '147, 8, 99',
            '900': '196, 153, 175',
            'text': '17, 17, 17',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '240, 240, 240',
            '50': '49, 60, 155',
            '100': '46, 59, 147',
            '200': '134, 134, 134',
            '300': '139, 139, 139',
            '400': '177, 177, 177',
            '500': '218, 218, 218',
            '600': '223, 223, 223',
            '700': '240, 240, 240',
            '800': '243, 244, 246',
            '900': '250, 248, 248',
            '950': '255, 255, 255',
            'text': '108, 108, 108',
        },
    },
    "default": {
        backdrop: "62, 18, 64",
        placeholderText: '140, 152, 192',
        actionButtonText: '254, 255, 254',
        buttonTextColor: '228, 229, 240',
        logo: '255, 0, 147',
        footerLogo: 'none',
        primary: {
            DEFAULT: '228, 37, 117',
            '50': '248, 200, 220',
            '100': '246, 182, 209',
            '200': '241, 146, 186',
            '300': '237, 110, 163',
            '400': '232, 73, 140',
            '500': '228, 37, 117',
            '600': '166, 51, 94',
            '700': '136, 17, 67',
            '800': '147, 8, 99',
            '900': '110, 0, 64',
            'text': '254, 255, 254',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '17, 29, 54',
            '50': '49, 60, 155',
            '100': '46, 59, 147',
            '200': '35, 42, 112',
            '300': '32, 41, 101',
            '400': '28, 39, 89',
            '500': '22, 37, 70',
            '600': '20, 33, 62',
            '700': '17, 29, 54',
            '800': '15, 25, 47',
            '900': '12, 21, 39',
            '950': '11, 17, 35',
            'text': '171, 181, 209',
        },
    },
    "evmos": {
        placeholderText: '128, 110, 107',
        actionButtonText: '254, 255, 254',
        buttonTextColor: '74, 61, 59',
        logo: '226, 49, 115',
        footerLogo: 'none',
        primary: {
            DEFAULT: '237, 78, 51',
            '50': '248, 200, 220',
            '100': '246, 182, 209',
            '200': '241, 146, 186',
            '300': '237, 110, 163',
            '400': '232, 73, 140',
            '500': '219, 211, 209',
            '600': '166, 51, 94',
            '700': '136, 17, 67',
            '800': '147, 8, 99',
            '900': '237, 78, 51',
            'text': '74, 61, 59',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '239, 239, 239',
            '50': '49, 60, 155',
            '100': '46, 59, 147',
            '200': '134, 134, 134',
            '300': '139, 139, 139',
            '400': '177, 177, 177',
            '500': '227, 227, 227',
            '600': '223, 223, 223',
            '700': '244, 243, 242',
            '800': '248, 247, 247',
            '900': '250, 248, 248',
            '950': '255, 255, 255',
            'text': '122, 91, 91',
        },
    },
    "ton": {
        placeholderText: '134, 134, 134',
        actionButtonText: '255, 255, 255',
        buttonTextColor: '15, 15, 15',
        logo: '15, 15, 15',
        footerLogo: 'none',
        primary: {
            DEFAULT: '51, 144, 236',
            '50': '248, 200, 220',
            '100': '246, 182, 209',
            '200': '241, 146, 186',
            '300': '237, 110, 163',
            '400': '232, 73, 140',
            '500': '45, 148, 229',
            '600': '166, 51, 94',
            '700': '136, 17, 67',
            '800': '45, 148, 229',
            '900': '51, 144, 236',
            'text': '15, 15, 15',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '240, 240, 240',
            '50': '190, 195, 200',
            '100': '199, 201, 206',
            '200': '208, 210, 211',
            '300': '212, 214, 219',
            '400': '220, 222, 226',
            '500': '227, 230, 233',
            '600': '229, 231, 235',
            '700': '241, 243, 245',
            '800': '243, 244, 246',
            '900': '250, 248, 248',
            '950': '255, 255, 255',
            'text': '106, 119, 133',
        },
    },
    "immutable": {
        placeholderText: '182, 182, 182',
        actionButtonText: '19, 19, 19',
        buttonTextColor: '243, 243, 243',
        logo: '15, 15, 15',
        headerLogo: 'none',
        footerLogo: 'block',
        footerLogoHeight: '20px',
        primary: {
            DEFAULT: '243, 243, 243',
            '50': '248, 200, 220',
            '100': '246, 182, 209',
            '200': '241, 146, 186',
            '300': '237, 110, 163',
            '400': '232, 73, 140',
            '500': '45, 148, 229',
            '600': '166, 51, 94',
            '700': '136, 17, 67',
            '800': '45, 148, 229',
            '900': '243, 243, 243',
            'text': '243, 243, 243',
            'textMuted': '86, 97, 123',
        },
        secondary: {
            DEFAULT: '240, 240, 240',
            '50': '190, 195, 200',
            '100': '199, 201, 206',
            '200': '208, 210, 211',
            '300': '212, 214, 219',
            '400': '243, 243, 243, 0.08',
            '500': '45, 45, 45',
            '600': '26, 26, 26',
            '700': '37, 37, 37',
            '800': '13, 13, 13',
            '900': '13, 13, 13',
            '950': '255, 255, 255',
            'text': '200, 200, 200',
        },
    }
}